import React, { Fragment } from "react";
import Col from "react-bootstrap/Col";
import * as styles from "./ClickableContentItem.module.scss";
import ClickableContentItemText from "./ClickableContentItemText";

const ClickableContentItem = (props) => {

    const itemClickHandler = (item) => {
        props.onClick(item);
    }

    return (
        <Fragment>
            {/*Renders numbers and body content on above mobile */}
            <Col md={6} lg className={`${styles.clickableContent__item} d-none d-md-block`}>
                <div className={`${props.activeItemIndex === props.itemIndex ? styles.active : ""}`} onClick={() => itemClickHandler(props.itemIndex)} key={props.itemIndex}>
                    <div className={`${styles.clickableContent__item__dots} ${props.isLast ? styles.last : ""}`}>
                        <div className={styles.clickableContent__item__number}>{props.itemIndex + 1}</div>
                    </div>
                    <div className={styles.clickableContent__item__content}>
                        <ClickableContentItemText body={props.body} title={props.title} />
                    </div>
                </div>
            </Col>

            {/*renders numbers only on mobile*/}
            <Col className={`${styles.clickableContent__item} d-block d-md-none`}>
                <div className={`${props.activeItemIndex === props.itemIndex ? styles.active : ""}`} key={props.itemIndex}>
                    <div className={`${styles.clickableContent__item__dots} ${props.isLast ? styles.last : ""}`}>
                        <div className={styles.clickableContent__item__number} onClick={() => itemClickHandler(props.itemIndex)}>
                            {props.itemIndex + 1}
                        </div>
                    </div>
                </div>
            </Col>
        </Fragment >
    )
};

export default ClickableContentItem;