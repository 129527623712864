import React from "react";
import { Seo } from "../components/Helpers/Seo";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import ClickableContentNavigator from "../components/ClickableContentNavigator/ClickableContentNavigator";
import SectionDivider from "../components/SectionDivider/SectionDivider";
import CallToAction from "../components/CallToAction/CallToAction";
import { graphql } from "gatsby";
import DOMPurify from "isomorphic-dompurify";
import Fade from "react-reveal/Fade";

// call out seo properties here
export const Head = ({ data }) => (
    <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} keywords={data.wpPage.seo.metaKeywords} />
);

const ApproachPage = ({ data }) => {
    const section1Content = data.wpPage.acfApproachPage.approachContentSection1;
    const section2Content = data.wpPage.acfApproachPage.approachContentSection2;
    const clickableContent = data.wpPage.clickableContentComponent.clickableContentComponent;
    const ctaContent = data.wpPage.ctaGlobal;

    return (
        <Layout backgroundClass="ellipse-background">
            <Container fluid className="ellipse-background">
                <Header />
                <Container>
                    <Row className="content-bottom-pad">
                        <Col xs={12} lg={6} className="text-center">
                            <Fade bottom distance={"40px"} duration={700}>
                                <img
                                    src={section1Content.sectionImage.localFile.publicURL}
                                    alt={section1Content.sectionImage.altText}
                                    className="d-none d-md-block"
                                    style={{ paddingTop: 63 + "px" }}
                                />
                                <img
                                    src={section1Content.sectionMobileImage.localFile.publicURL}
                                    alt={section1Content.sectionMobileImage.altText}
                                    className="d-block d-md-none"
                                    style={{ paddingTop: 63 + "px" }}
                                />
                            </Fade>
                        </Col>
                        <Col xs={12} lg={6} className="ellipse-intro-text">
                            <h1
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section1Content.heading),
                                }}
                            />
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section1Content.bodyText),
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
                <SectionDivider />
            </Container>
            <Container fluid style={{ paddingTop: 90 + "px" }}>
                <Container>
                    <Row>
                        <Col xs={12} lg={6}>
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(section2Content.heading),
                                }}
                            />
                        </Col>
                        {section2Content.bodyText?.trim() !== "" && (
                            <Col xs={12} lg={6}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(section2Content.bodyText),
                                    }}
                                />
                            </Col>
                        )}
                    </Row>
                    <Row>
                        {
                            <ClickableContentNavigator
                                items={clickableContent.componentItems}
                                heading={clickableContent.componentTitle}
                            />
                        }
                    </Row>
                </Container>
            </Container>
            <CallToAction content={ctaContent} />
        </Layout>
    );
};

export default ApproachPage;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 15 }) {
            seo {
                metaDesc
                title
                metaKeywords
            }
            acfApproachPage {
                approachContentSection1 {
                    heading
                    bodyText
                    sectionImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                    sectionMobileImage {
                        localFile {
                            publicURL
                        }
                        altText
                    }
                }
                approachContentSection2 {
                    heading
                    bodyText
                }
            }
            clickableContentComponent {
                clickableContentComponent {
                    componentTitle
                    componentItems {
                        title
                        body
                        desktopImage {
                            localFile {
                                publicURL
                            }
                            altText
                        }
                        mobileImage {
                            localFile {
                                publicURL
                            }
                            altText
                        }
                    }
                }
            }
            ctaGlobal {
                description
                title
                link {
                    url
                    title
                    target
                }
            }
        }
    }
`;
