import React, { Fragment } from "react";
import DOMPurify from "isomorphic-dompurify";

const ClickableContentBody = (props) => {
    return (
        <Fragment>
            <div>
                <h4>{props.title}</h4>
            </div>
            <div
                className="small-body-copy"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.body),
                }}
            />
        </Fragment>
    );
};

export default ClickableContentBody;
