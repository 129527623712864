import React, { Fragment, useState } from "react";
import { Row, Col } from "react-bootstrap";
import * as styles from "./ClickableContentNavigator.module.scss";
import ClickableContentItem from "./ClickableContentItem";
import ClickableContentItemText from "./ClickableContentItemText";
import Fade from "react-reveal/Fade";

const ClickableContentNavigator = (props) => {
    const [navigatorItems, setNavigatorItems] = useState(props);
    const [activeIndex, setActiveIndex] = useState(0);

    const clickableContentItemClickHandler = (item) => {
        setNavigatorItems({ ...navigatorItems, activeIndex });
        setActiveIndex(item);
    };

    return (
        <Fragment>
            <div className={styles.clickableContent}>
                <Row className={styles.clickableContent__image}>
                    <Col>
                        {navigatorItems.items.map((itemImage, index) => {
                            return (
                                <Fade bottom distance={"40px"} duration={700}>
                                    <img
                                        src={itemImage.desktopImage?.localFile.publicURL}
                                        alt={itemImage.altText}
                                        key={`desktop-${index}`}
                                        className={`${styles.clickableContent__image__item} ${activeIndex === index
                                            ? styles.clickableContent__image__item__active + " d-none d-md-block"
                                            : ""
                                            } `}
                                    />
                                    <img
                                        src={itemImage.mobileImage?.localFile.publicURL}
                                        alt={itemImage.altText}
                                        key={`mobile-${index}`}
                                        className={`${styles.clickableContent__image__item} ${activeIndex === index
                                            ? styles.clickableContent__image__item__active + " d-block d-md-none"
                                            : ""
                                            }`}
                                    />
                                </Fade>
                            );
                        })}
                    </Col>
                </Row>
                {/*Only show the heading in this position if it exist*/}
                {props.heading?.trim() !== "" && (
                    <Row>
                        <Col>
                            <h4 className={styles.clickableContent__heading}>{props.heading}</h4>
                        </Col>
                    </Row>
                )}
                <Row style={{ marginRight: 0 + "px" }}>
                    {navigatorItems.items.map((item, index) => (
                        <ClickableContentItem
                            body={item.body}
                            title={item.title}
                            itemIndex={index}
                            key={index}
                            activeItemIndex={activeIndex}
                            isLast={index === navigatorItems.items.length - 1}
                            onClick={clickableContentItemClickHandler}
                        />
                    ))}
                </Row>
                <Row className="d-md-none" style={{ marginRight: 0 + "px" }}>
                    {navigatorItems.items.map((item, index) => {
                        return (
                            activeIndex === index && (
                                <div key={index}>
                                    <ClickableContentItemText body={item.body} title={item.title} />
                                </div>
                            )
                        );
                    })}
                </Row>
            </div>
        </Fragment>
    );
};

export default ClickableContentNavigator;
