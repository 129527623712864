import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "./SectionDivider.module.scss";

const SectionDivider = (props) => {
    return (
        <Fragment>
            <Container fluid>
                <Row className={styles.sectionDivider}>
                    <Col className={styles.sectionDivider__left} xs={8} lg={6}>
                        &nbsp;
                    </Col>
                    <Col className={styles.sectionDivider__right} xs={4} lg={6}>
                        &nbsp;
                    </Col>
                    <div className={`${styles.sectionDivider__pattern} ${props.hidePattern === "true" ? styles.hide : ""}`} />
                </Row>
            </Container>
        </Fragment>
    );
};

export default SectionDivider;
